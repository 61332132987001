// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-babysprache-js": () => import("./../../../src/pages/babysprache.js" /* webpackChunkName: "component---src-pages-babysprache-js" */),
  "component---src-pages-coaching-prozess-js": () => import("./../../../src/pages/coaching-prozess.js" /* webpackChunkName: "component---src-pages-coaching-prozess-js" */),
  "component---src-pages-cookie-js": () => import("./../../../src/pages/cookie.js" /* webpackChunkName: "component---src-pages-cookie-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-erfahrungsberichte-js": () => import("./../../../src/pages/erfahrungsberichte.js" /* webpackChunkName: "component---src-pages-erfahrungsberichte-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-oss-js": () => import("./../../../src/pages/oss.js" /* webpackChunkName: "component---src-pages-oss-js" */),
  "component---src-pages-uebermich-js": () => import("./../../../src/pages/uebermich.js" /* webpackChunkName: "component---src-pages-uebermich-js" */),
  "component---src-pages-zeitumstellung-js": () => import("./../../../src/pages/zeitumstellung.js" /* webpackChunkName: "component---src-pages-zeitumstellung-js" */)
}

